import React from 'react'
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'
// import { NavLink } from "react-router-dom"
import logo from '../assets/logo.svg';


const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1180 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1179 })
    return isMobile ? children : null
}



export function Header(props) {

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);


    return (
        <>
            <header>

                <div className={scroll ? "sticky sticky-fixed" : "sticky"}>

                    <div className='container'>
                        <div className='row align-items-center justify-content-between'>
                            <div className='col-lg-3 col-6'>
                                <a href='#home' title='Hydranet Web Wallet'>  <img src={logo} title='Logo' alt='Hydranet Logo'></img></a>
                            </div>

                            <Desktop>
                                <div className='col-lg-6'>
                                    <nav id="main-nav" className="main-menu navbar navbar-expand-lg">

                                        <div className="collapse navbar-collapse" id="navbarMain">
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <a href='#home' className="nav-link active">Home</a>
                                                    {/* <NavLink className="nav-link" to="#home">Home</NavLink> */}
                                                </li>
                                                <li className="nav-item">
                                                    <a href='#features' className="nav-link">Features</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href='#comparison' className="nav-link">Comparison</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href='#faqs' className="nav-link">FAQs</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href='#contact' className="nav-link">Contact</a>
                                                </li>
                                            </ul>

                                        </div>

                                    </nav>

                                </div>
                            </Desktop>

<Mobile>



<div className="offcanvas offcanvas-start sidebar" tabIndex="-1" id="sideBar" aria-labelledby="sidebarLabel">
  <div className="offcanvas-header">
    <h5 className="offcanvas-title" id="sidebarLabel">Menu</h5>
    <button type="button" className="button-close" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
  </div>
  <div className="offcanvas-body">
    <div>
    <nav id="main-nav" className="sidebar-menu navbar navbar-expand">

<div className="collapse navbar-collapse" id="navbarMain">
    <ul className="navbar-nav">
        <li className="nav-item" data-bs-dismiss="offcanvas">
            <a href='#home' className="nav-link active">Home</a>
            {/* <NavLink className="nav-link" to="#home">Home</NavLink> */}
        </li>
        <li className="nav-item" data-bs-dismiss="offcanvas">
            <a href='#features' className="nav-link">Features</a>
        </li>
        <li className="nav-item" data-bs-dismiss="offcanvas">
            <a href='#comparison' className="nav-link">Comparison</a>
        </li>
        <li className="nav-item" data-bs-dismiss="offcanvas">
            <a href='#faqs' className="nav-link">FAQs</a>
        </li>
        <li className="nav-item" data-bs-dismiss="offcanvas">
            <a href='#contact' className="nav-link">Contact</a>
        </li>
    </ul>

</div>

</nav>
    </div>

  </div>
</div>
</Mobile>

                            <div className='col-lg-3 col-6 d-flex justify-content-end'>
                                <Mobile>
                                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideBar" aria-controls="sideBar">
                                <i className="bi bi-text-left"></i>
                                </button>
                                </Mobile>
                                <Desktop>
                                    <a className='button-default' rel='noreferrer' href='https://wallet.hydranet.ai' target='_blank' title='Launch Now'>
                                        Launch Now
                                    </a>
                                </Desktop>
                            </div>


                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
