import React from 'react';
import { Routes, Route } from "react-router-dom"
import { Layout } from './pages/layout';
import { Index } from './pages';
// import Terms from './pages/terms';
import './App.css';
import './assets/icons.min.css';

function App() {
  return (
    <Routes>  
      <Route path='/' element={<Layout />} >
        <Route index={true} element={<Index />} />
        {/* <Route path='terms' element={<Terms />} /> */}
      </Route>
    </Routes> 
  );    
}  

export default App;
